import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { baseUrl } from "../../Services/AxiosServices";

const Header = ({ callback }) => {
  // const videoSrc = `${baseUrl}Video/StreamVideo/stream`;

  const [isExpertiseOpen, setIsExpertiseOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const expertiseDropdownRef = useRef(null);
  const menuRef = useRef(null);

  const toggleExpertiseDropdown = (e) => {
    e.preventDefault();
    setIsExpertiseOpen(!isExpertiseOpen);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    if (!isMenuOpen) {
      setIsExpertiseOpen(false);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        expertiseDropdownRef.current &&
        !expertiseDropdownRef.current.contains(event.target)
      ) {
        setIsExpertiseOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isExpertiseOpen]);

  return (
    <header id="home" className="welcome-area">
      <div className="header-top-area">
        <div className="container">
          <div className="row">
            <div className="col-sm-3">
              <div className="logo">
                <img
                  className="LogoImg"
                  src="/assets/images/pensoftLogowhite.png"
                  alt="Logo"
                />
              </div>
            </div>
            <div className="col-sm-9 ">
              <div className="mainmenu mainmenuPadding">
                <div className="navbar navbar-nobg">
                  <div className="navbar-header">
                    <button
                      type="button"
                      className="navbar-toggle"
                      onClick={toggleMenu}
                    >
                      <span className="sr-only">Toggle navigation</span>
                      <span className="icon-bar" />
                      <span className="icon-bar" />
                      <span className="icon-bar" />
                    </button>
                  </div>
                  <div
                    className={`navbar-collapse collapse ${isMenuOpen ? "in" : ""
                      }`}
                    ref={menuRef}
                    style={{
                      maxHeight: isMenuOpen ? "400px" : "none", // Limit height for scroll
                      overflowY: isMenuOpen ? "auto" : "visible", // Enable scrolling if open
                    }}
                  >
                    <ul className="nav navbar-nav navbar-right">
                      <li className="active">
                        {/* <a className="smoth-scroll" href="/OurUniverse">
                          OUR UNIVERSE
                        </a> */}
                        <Link
                          to={"/OurUniverse"}
                          rel="stylesheet"
                          className="smoth-scroll"
                        >
                          OUR UNIVERSE
                        </Link>
                      </li>
                      <li
                        className={`dropdown ${isExpertiseOpen ? "open" : ""}`}
                        ref={expertiseDropdownRef}
                        style={{ position: "relative" }}
                      >
                        {/* <a
                          className="smoth-scroll dropdown-toggle"
                          href="#"
                          data-toggle="dropdown"
                          onClick={toggleExpertiseDropdown}
                        >
                          EXPERTISE
                        </a> */}
                        <Link
                          className="smoth-scroll dropdown-toggle"
                          href="#"
                          data-toggle="dropdown"
                          onClick={toggleExpertiseDropdown}
                        >
                          EXPERTISE
                        </Link>
                        <ul
                          className="dropdown-menu"
                          style={{
                            display: isExpertiseOpen ? "block" : "none",
                          }}
                        >
                          <li className="subMenu">
                            {/* <a href="/DigitalTransformation">
                              Digital Transformation
                            </a> */}
                            <Link
                              to={"/DigitalTransformation"}
                            // rel="stylesheet"
                            // className="smoth-scroll"
                            >
                              Digital Transformation
                            </Link>
                          </li>
                          <li className="subMenu">
                            {/* <a href="/OracleConsulting">Oracle Consulting</a> */}
                            <Link
                              to={"/OracleConsulting"}
                            // rel="stylesheet"
                            // className="smoth-scroll"
                            >
                              Oracle Consulting
                            </Link>
                          </li>
                          <li className="subMenu">
                            {/* <a href="/SAPConsulting">SAP Consulting</a> */}
                            <Link
                              to={"/SAPConsulting"}
                            // rel="stylesheet"
                            // className="smoth-scroll"
                            >
                              SAP Consulting
                            </Link>
                          </li>
                          <li className="subMenu">
                            {/* <a href="/ProfessionalStaffing">
                              Professional Staffing
                            </a> */}
                            <Link
                              to={"/ProfessionalStaffing"}
                            // rel="stylesheet"
                            // className="smoth-scroll"
                            >
                              Professional Staffing
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <Link
                          to={"/Excellence"}
                          rel="stylesheet"
                          className="smoth-scroll"
                        >
                          EXCELLENCE
                        </Link>
                        {/* <a className="smoth-scroll" href="/Excellence">
                          EXCELLENCE
                        </a> */}
                      </li>
                      <li>
                        <Link
                          to={"/PenTalk"}
                          rel="stylesheet"
                          className="smoth-scroll"
                        >
                          PEN TALK
                        </Link>
                        {/* <a className="smoth-scroll" href="/PenTalk">
                          PEN TALK
                        </a> */}
                      </li>
                      <li>
                        <Link
                          to={"/Oppourtunities"}
                          rel="stylesheet"
                          className="smoth-scroll"
                        >
                          OPPORTUNITIES
                        </Link>
                        {/* <a className="smoth-scroll" href="/Oppourtunities">
                          OPPORTUNITIES
                        </a> */}
                      </li>
                      <li>
                        <Link
                          to={"/Connectwithus"}
                          rel="stylesheet"
                          className="smoth-scroll"
                        >
                          LET'S CONNECT
                        </Link>
                        {/* <a className="smoth-scroll" href="/Connectwithus">
                          LET'S CONNECT
                        </a> */}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="welcome-image-area">
        {/* <video
          autoPlay
          muted
          loop
          id="background-video"
          style={{ objectFit: "cover" }}
        >
          <source src={`${process.env.REACT_APP_API_IMAGEPATH}/stream`} type="video/mp4" />

        </video> */}

        {/* <video
          src={videoSrc}
          autoPlay
          muted
          loop
          id="background-video"
          className="background-video"
        /> */}

        {/*<img
          src="/assets/images/Background_Image.png"
          id="background-video"
          className="background-video"
        />*/}

        <video
          src={"/assets/images/Banner_Video1.mp4"}
          autoPlay
          muted
          loop
          preload="auto"
          id="background-video"
          className="background-video"
          onLoadedData={(e) => {
            callback();
          }}
        />

        <div className="overlay" />
        <div className="display-table">
          <div className="display-table-cell">
            <div className="container">
              <div className="row">
                <div className="col-md-12  text-center">
                  <div className="header-text">
                    <h2>
                      <b> Empowering The Digital Universe </b>
                    </h2>
                    <p>
                      CRAFTING UNIQUE IT SOLUTIONS FOR YOUR BUSINESS GALAXY'S
                      GROWTH
                    </p>
                    <a className="slide-btn smoth-scroll" href="/OurUniverse">
                      Learn More
                    </a>
                    <a className="slide-btn smoth-scroll" href="/Connectwithus">
                      Contact Us
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
