import React, { useState } from "react";
import Swal from "sweetalert2";
import AuthenticationService from "../../../Services/LoginService";
import "./Login.css";
import Button from "../AdminPenTalk/Button/Button";
import { baseUrl } from "../../../Services/AxiosServices";

const auth = new AuthenticationService();

const ForgotPassword = () => {
  // const videoSrc = `${baseUrl}Video/StreamVideo/stream`;

  const [form, setForm] = useState({ Username: "" });
  const [dummy, setDummy] = useState({ errUserName: false });
  const [error, setError] = useState("");
  const [isLoaded, setIsLoaded] = useState(false);

  const handleChange = (e) => {
    const { value } = e.target;
    setForm({ ...form, Username: value });

    if (value.trim()) {
      setDummy({ errUserName: false });
    }
  };

  const loginSubmit = async (e) => {
    e.preventDefault();

    let isValid = true;

    if (!form.Username.trim()) {
      setDummy({ errUserName: true });
      setError("");
      isValid = false;
    } else if (!/^[a-zA-Z]{5,15}$/.test(form.Username)) {
      setDummy({ errUserName: true });
      setError("");
      isValid = false;
    } else {
      setDummy({ errUserName: false });
      setError("");
    }

    if (!isValid) {
      return;
    }

    try {
      setIsLoaded(true);
      const response = await auth.getOtpData(form.Username);

      if (response.data > 0) {
        localStorage.setItem("username", form.Username);
        window.location.href = "/OtpVerification";
      } else if (response === 0) {
        Swal.fire({
          icon: "error",
          title: "Invalid username",
          position: "top-end",
          toast: true,
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
        setError("Invalid username.");
      } else {
        Swal.fire({
          icon: "error",
          title: "User not found",
          position: "top-end",
          toast: true,
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      }
    } catch (error) {
      console.error("Request failed:", error.message);
      Swal.fire({
        icon: "error",
        title: "Something went wrong",
        position: "top-end",
        toast: true,
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
      });
    } finally {
      setIsLoaded(false);
    }
  };
  const hideLoader = () => {
    $(".preloader").css("display", "none");
  }
  return (
    <div className="ps-login">
      {/* <video
        src={videoSrc}
        autoPlay
        muted
        loop
        id="loginbackground-video"
        className="loginbackground-video"
      /> */}
      {/* <img
        src="/assets/images/Background_Image.png"
        id="loginbackground-video"
        className="loginbackground-video"
      /> */}
      <video
        src={"/assets/images/Banner_Video1.mp4"}
        autoPlay
        muted
        loop
        preload="auto"
        id="loginbackground-video"
        className="loginbackground-video"
        onLoadedData={(e) => {
          hideLoader();
        }}
      />
      <div className="login-container">
        <div className="text-center login-logo">
          <img
            src="/assets/images/pensoftLogowhite.png"
            alt="Logo"
            className="LogoImg"
          />
        </div>

        <div className="login-custom">
          <form onSubmit={loginSubmit}>
            <div
              className={`form-group ${dummy.errUserName ? "has-error" : ""}`}
            >
              <label htmlFor="UserName" className="email-label">
                Username
              </label>
              <input
                type="text"
                className={`form-control email-input ${dummy.errUserName ? "is-invalid" : ""
                  }`}
                value={form.Username}
                onChange={handleChange}
              />
              {dummy.errUserName && (
                <span className="text-danger" style={{ fontSize: "14px" }}>
                  {error}
                </span>
              )}
            </div>

            <div
              className="form-group action-buttons button-align"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <a
                className="forgot-password"
                href="/admin"
                style={{ marginRight: "10px" }}
              >
                Back to Login
              </a>
              {!isLoaded ? (
                <div className="continue-btn">
                  <Button name="Continue" onClick={loginSubmit} />
                </div>
              ) : (
                <button className="btn btn-success" type="button" disabled>
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Loading...
                </button>
              )}
            </div>

            {error && !dummy.errUserName && (
              <div className="text-danger" style={{ marginTop: "10px" }}>
                {error}
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
